@media (max-width: 700px) {
	.toolbar-flex-container {
		flex-direction: column;
	}
	/* .flex-tools-group {
		justify-content: space-between;
	} */
	/* .custom-input-box {
		width: '100%';
	} */
}
